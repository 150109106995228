import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const SKUS: AppRouteRecordRaw = {
  path: '/skus',
  name: 'skus',
  component: DEFAULT_LAYOUT,
  meta: {
    icon: 'icon-dashboard',
    order: 0,
    title: '数字藏品管理',
  },
  children: [
    {
      path: 'create',
      name: 'skus-create',
      meta: {
        roles: ['create'],
        title: '创建数字藏品',
      },
      component: () => import('@/views/skus/create/CreateNft.vue'),
    },
    {
      path: 'batch',
      name: 'batch-creation',
      meta: {
        roles: ['create'],
        title: '批量创建数字藏品',
      },
      component: () => import('@/views/skus/batchCreation/BatchCreation.vue'),
    },
    {
      path: 'list',
      name: 'skus-list',
      meta: {
        title: '数字藏品列表',
      },
      component: () => import('@/views/skus/list/index.vue'),
    },
    {
      path: 'list/detail',
      name: 'skus-list-detail',
      meta: {
        activeName: 'skus-list',
      },
      component: () => import('@/views/skus/detail/index.vue'),
    },
  ],
};

export default SKUS;
