<template>
  <a-breadcrumb class="container-breadcrumb">
    <a-breadcrumb-item v-if="!isBack">
      <icon-apps />
    </a-breadcrumb-item>
    <a-breadcrumb-item v-else>
      <a-link @click="goBack"><icon-left /> 返回</a-link>
    </a-breadcrumb-item>
    <a-breadcrumb-item v-for="item in items" :key="item">
      {{ item }}
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue';
  import { useRouter } from 'vue-router';

  const router = useRouter();

  const props: any = defineProps({
    isBack: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<string[]>,
      default() {
        return [];
      },
    },
  });

  const goBack = () => {
    if (props.isBack === true) {
      window.history.back();
    } else {
      router.push(props.isBack);
    }
  };
</script>

<style scoped lang="less">
  .container-breadcrumb {
    margin: 16px 0;
    :deep(.arco-breadcrumb-item) {
      color: rgb(var(--gray-6));
      &:last-child {
        color: rgb(var(--gray-8));
      }
    }
  }
</style>
