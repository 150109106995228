import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { useUserStore } from '@/store';

export default function usePermission() {
  const userStore = useUserStore();
  return {
    accessRouter(route: RouteLocationNormalized | RouteRecordRaw) {
      const roles = route.meta?.roles || [];
      if (userStore.userInfo.isAdmin) {
        roles.push(userStore.userInfo.isAdmin);
      }
      return (
        !roles.length ||
        roles?.includes(userStore.userInfo.isAdmin) ||
        roles?.every((role) => {
          return (userStore.userInfo.permissions as string[]).includes(role);
        })
      );
    },
    accessActionRouter(actionId: string) {
      return (userStore.userInfo.permissions as string[]).includes(actionId);
    },
  };
}
