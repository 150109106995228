import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { setToken, clearToken } from '@/utils/auth';
import {
  login as userLogin,
  logout as userLogout,
  getUserInfo,
  LoginData,
} from '@/api/user';

const useUserStore = defineStore('user', () => {
  const userInfo = ref({
    id: '',
    username: '',
    realname: '',
    permissions: [],
    role: '',
    isAdmin: '', // 超级管理员
  });

  const login = (form: LoginData) => {
    return userLogin(form).then(({ data }: { data: any }) => {
      if (data.access_token) {
        setToken(data.access_token);
      }
      return data;
    });
  };

  const info = async () => {
    return getUserInfo().then(({ data }: { data: any }) => {
      const options = {
        id: data.id,
        username: data.username,
        realname: data.realname || data.username,
        permissions: data.permissions.map((permission: any) => permission.key),
        role: String(data?.role?.id ?? ''),
        isAdmin: data.is_admin === 1 ? 'admin' : '', // 定义超管权限
      };
      if (options.isAdmin) {
        options.permissions = ['admin'];
      }
      Object.assign(userInfo.value, options);
    });
  };

  const logout = () => {
    return userLogout().finally(() => {
      clearToken();
      userInfo.value = {
        id: '',
        username: '',
        realname: '',
        role: '',
        permissions: [],
        isAdmin: '',
      };
    });
  };

  return {
    userInfo: computed(() => userInfo.value),
    login,
    logout,
    info,
  };
});

export default useUserStore;
