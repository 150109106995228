export default {
  navbar: true,
  menu: true,
  topMenu: false,
  hideMenu: false,
  menuCollapse: false,
  footer: true,
  themeColor: '#165DFF',
  menuWidth: 220,
  globalSettings: false,
  device: 'desktop',
  tabBar: false,
};
