import reuqest from '@/utils/request';
import { UserState } from '@/store/modules/user/types';

export interface LoginData {
  username: string;
  password: string;
}

export interface LoginRes {
  token: string;
}

export const login = (params: LoginData) =>
  reuqest.post<LoginRes>('/api/login', params);

export const logout = () => reuqest.post<LoginRes>('/api/logout');

export const getUserInfo = () => reuqest.get<UserState>('/api/users/info');
