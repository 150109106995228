import axios from 'axios';
import qs from 'qs';
import { Message } from '@arco-design/web-vue';

import { getToken } from './auth';

const handleError = (options: any) => {
  if (options?.status === 401) {
    Message.error({
      content: '登录超时，请重新登录！',
      duration: 2000,
      onClose: () => {
        window.location.reload();
      },
    });
  } else {
    Message.error(options.message || '系统异常，请重新再试！');
  }
};

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 50000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  transformRequest: [
    (data = {}) =>
      qs.stringify(data, {
        arrayFormat: 'brackets',
      }),
  ],
});

instance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    (config.headers as any).Authorization = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
    handleError(response);
    return Promise.reject(response);
  },
  (error) => {
    let result = error;
    if (error.response) {
      result = {
        status: error.response?.status,
        message: error.response?.data?.message,
        extend: result?.response?.data,
      };
    }

    handleError(result);
    return Promise.reject(result);
  }
);

export default instance;
