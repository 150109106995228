import { createApp } from 'vue';
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
// import * as Sentry from '@sentry/vue';

import globalComponents from '@/components';
import router from './router';
import store from './store';
import directive from './directive';
import App from './App.vue';
import '@/assets/style/tailwind.css';
// Styles are imported via arco-plugin. See config/plugin/arcoStyleImport.ts in the directory for details
// 样式通过 arco-plugin 插件导入。详见目录文件 config/plugin/arcoStyleImport.ts
// https://arco.design/docs/designlab/use-theme-package
import '@/assets/style/global.less';
// import '@/api/interceptor';

const app = createApp(App);

// if (import.meta.env.MODE === 'production') {
//   Sentry.init({
//     app,
//     dsn: 'https://8b23ac8e15d856ebfa5371ba404bf0f1@sentry.feiyangyinxiang.cn/5',
//     debug: false,
//     integrations: [
//       Sentry.browserTracingIntegration({ router, idleTimeout: 3000 }),
//       Sentry.replayIntegration(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

app.use(ArcoVue, {});
app.use(ArcoVueIcon);
app.use(router);
app.use(store);
app.use(globalComponents);
app.use(directive);

app.mount('#app');
