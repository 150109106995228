<template>
  <div class="p-6 pt-0">
    <slot v-if="isCrumb" name="crumb" />
    <Breadcrumb v-else :items="crumbData" :is-back="crumbIsBack" />
    <a-card class="general-card" :title="title">
      <slot />
    </a-card>
  </div>
  <div v-if="isOperate">
    <div class="h-[60px]"></div>
    <div
      class="fixed left-0 right-0 bottom-0 h-[60px] py-[14px] pr-[20px] bg-[#fff] text-right"
    >
      <slot name="operate" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useSlots, computed } from 'vue';
  import { useRoute } from 'vue-router';

  const props = defineProps({
    crumbIsBack: {
      type: Boolean,
      default: false,
    },
    crumbItems: {
      type: Array,
      default() {
        return [];
      },
    },
    cardTitle: {
      type: String,
      default: '',
    },
  });

  const slots = useSlots();
  const route = useRoute();

  const isSlot = (slotName: string) => !!slots[slotName];

  const isCrumb = computed(() => !!isSlot('crumb'));
  const isOperate = computed(() => !!isSlot('operate'));
  const crumbData = computed(() => {
    if (props.crumbItems.length) {
      return props.crumbItems;
    }
    const processData: string[] = [];
    route.matched.forEach((item) => {
      if (item?.meta?.title) {
        processData.push(item?.meta?.title as string);
      }
    });
    return processData;
  });
  const title = computed(
    () =>
      (props.cardTitle || crumbData.value[crumbData.value.length - 1]) as string
  );
</script>
