import type { Router, LocationQueryRaw } from 'vue-router';
import NProgress from 'nprogress'; // progress bar

import { useUserStore } from '@/store';
import { isLogin } from '@/utils/auth';

export default function setupUserLoginInfoGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    NProgress.start();
    if (['login'].includes(to.name as string)) {
      next();
    } else {
      const userStore = useUserStore();
      if (isLogin()) {
        if (userStore.userInfo.username) {
          next();
        } else {
          try {
            await userStore.info();
            next();
          } catch (error) {
            next({ path: '/login' });
          }
        }
      } else {
        next({
          path: '/login',
          query: {
            redirect: encodeURIComponent(to.path),
            ...to.query,
          } as LocationQueryRaw,
        });
      }
    }

    NProgress.done();
  });
}
