import { App } from 'vue';

import Breadcrumb from './breadcrumb/index.vue';
import ContentLayout from './contentLayout/index.vue';
import SmallDot from './smallDot/index.vue';

export default {
  install(Vue: App) {
    Vue.component('Breadcrumb', Breadcrumb);
    Vue.component('ContentLayout', ContentLayout);
    Vue.component('SmallDot', SmallDot);
  },
};
