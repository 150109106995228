import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const SKUS: AppRouteRecordRaw = {
  path: '/set',
  name: 'set',
  component: DEFAULT_LAYOUT,
  meta: {
    icon: 'icon-settings',
    order: 1,
    title: '设置',
    roles: ['admin'], // 管理员权限
  },
  children: [
    {
      path: 'role',
      name: 'set-role',
      meta: {
        title: '用户组管理',
      },
      component: () => import('@/views/set/role/index.vue'),
    },
    {
      path: 'manage',
      name: 'set-manage',
      meta: {
        title: '管理员列表',
      },
      component: () => import('@/views/set/manage/index.vue'),
    },
  ],
};

export default SKUS;
