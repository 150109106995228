import type { Router } from 'vue-router';
import NProgress from 'nprogress'; // progress bar

import usePermission from '@/hooks/permission';

export default function setupPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const Permission = usePermission();

    const permissionsAllow = Permission.accessRouter(to);

    if (permissionsAllow) {
      next();
    } else {
      next('/skus/list');
    }

    NProgress.done();
  });
}
